import React from 'react';
import { Divider, Font14G1W400, Font14G5W400, Font16G1W500, Frame, FrameV2, Spacing } from '@components/UtilsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, IconLeftWrap, TopSection, ActionItem, TitleWrap, TopInner } from './style';
import { isEmpty } from 'lodash';
import { BasicDropdown } from '@components/Dropdown';
import { GhostButton } from '@components/Button';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';

export type ICardProps = {
  title: string | React.ReactNode;
  description?: string;
  divider?: boolean;
  actionList?: { label: React.ReactNode; value: string; icon?: JSX.Element }[];
  titleExtra?: React.ReactNode;
  topLeftIcon?: React.ReactNode;
  children?: React.ReactNode;
  minWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  className?: string;
  _renderCustomActionList?: () => React.ReactNode;
  onClickAction?: ({ label, value, icon }: { label: React.ReactNode; value: string; icon?: JSX.Element }) => void;
};

export const Card = ({
  title,
  divider,
  topLeftIcon,
  description,
  actionList = [],
  titleExtra,
  children,
  minWidth,
  minHeight,
  maxHeight,
  className,
  _renderCustomActionList,
  onClickAction,
}: ICardProps) => {
  const _renderActionList = () => {
    return actionList.map((item) => {
      return (
        <ActionItem key={item.value} onClick={() => (onClickAction ? onClickAction(item) : false)}>
          {item.icon ? item.icon : null}
          <Font14G1W400>{item.label}</Font14G1W400>
        </ActionItem>
      );
    });
  };

  return (
    <Container className="overflow-hidden" minWidth={minWidth} minHeight={minHeight} maxHeight={maxHeight}>
      <TopSection>
        <TopInner>
          <div>
            <TitleWrap>
              {topLeftIcon ? <IconLeftWrap>{topLeftIcon}</IconLeftWrap> : null}
              <Font16G1W500>{title}</Font16G1W500>
              {titleExtra ? <div>{titleExtra}</div> : null}
            </TitleWrap>
            {description ? (
              <>
                <Spacing height={4} />
                <Font14G5W400>{description}</Font14G5W400>
              </>
            ) : null}
          </div>
        </TopInner>
        {_renderCustomActionList ? _renderCustomActionList() : null}
        {!isEmpty(actionList) ? (
          <div>
            <FrameV2 centered width={24} height={24}>
              <BasicDropdown trigger={['click']} overlayContent={_renderActionList()}>
                <GhostButton>
                  <FontAwesomeIcon icon={faEllipsisV} fontSize={18} />
                </GhostButton>
              </BasicDropdown>
            </FrameV2>
          </div>
        ) : null}
      </TopSection>
      {divider ? <Divider /> : null}
      <div
        style={{
          maxHeight: maxHeight ? `calc(${maxHeight} - 130px)` : 'auto',
          scrollbarGutter: 'stable',
        }}
        className={className}
      >
        <div>{children}</div>
      </div>
    </Container>
  );
};
