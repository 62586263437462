import React, { useCallback, useEffect, useRef } from 'react';
import { DownButton } from '@pages/components/DownButton';
import { useMachine } from '@xstate/react';
import messageBoxMachine from './messageBoxMachine';
import { CombineAllMessage, IRealMessage, TemplateFailed } from '@model/MessageItem';
import {
  checkLink,
  checkVideoLink,
  genTemplatePayload,
  getMessage,
  getMessageFromTemplate,
  isPersonMessage,
} from './fns';
import dayjs from 'dayjs';
import {
  BOTS,
  IImageMessage,
  ILinkMessage,
  IMember,
  INewMessage,
  ISocketEvent,
  ITextMessage,
  ITicket,
  IUser,
  IVideoMessage,
} from '@types';
import { IMessageBoxOptions } from '.';
import SocketIO from '@socket';
import Upload, { RcFile } from 'antd/lib/upload';
import { SocketMessage } from '@types';
import { ESenderType } from '@enums/SenderType';
import { EMessageStatus } from '@enums/MessageStatus';
import { EMessageType } from '@enums/MessageType';
import { EEventType } from '@enums/EventType';
import { messagePayloadGenerator } from '@utils/messagePayloadGenerator';
import { ETicketStatus } from '@enums/TicketStatus';
import {
  MESSAGE_BOX_BOTTOM_THRESHOLD,
  PERMISSION_DENIED,
  PERMISSION_DENIED_NAME_ERROR,
  REFRESH_PAGE_BUTTON,
} from '@configs/constants';
import { useAlertModal } from '@providers/AlertModalProvider';
import { validateFiles } from '@utils/fileUpload';
import { FILE_TYPE } from '@configs/fileType';
import { privateAction } from '@utils/privateAction';
import { useHistory } from 'react-router-dom';

const useMessageBox = (
  ticket: ITicket,
  messageTime: number | null,
  member: IMember,
  user: IUser,
  options?: IMessageBoxOptions,
) => {
  const history = useHistory();
  const socket = useRef<SocketIO | null>(null);
  const listRef = useRef<HTMLDivElement>({} as HTMLDivElement);
  const uploadRef = useRef<HTMLButtonElement>(null);
  const _messageBoxMachine = useCallback(() => {
    return messageBoxMachine(user._id, ticket.channelType, member, messageTime, ticket, options);
  }, [member, messageTime, options, ticket, user._id]);
  const [current, send] = useMachine(_messageBoxMachine, {
    devTools: import.meta.env.DEV,
  });
  const { onOpen, onUpdateDetail } = useAlertModal();
  const { chatHistory, scrollBottom, previousScrollHeight } = current.context;

  useEffect(() => {
    const body = document.body;
    body.addEventListener('paste', onPasted);

    return () => {
      body.removeEventListener('paste', onPasted);
    };
  }, [ticket]);

  const onPasted = (e: ClipboardEvent) => {
    if (e.clipboardData?.files.length) {
      const fileObject = e.clipboardData.files[0];
      const fileList = [fileObject];
      if (!ticket) return Upload.LIST_IGNORE;
      const validateResult = validateFiles(ticket, fileList, uploadRef, onUpdateDetail, onOpen);
      if (typeof validateResult === 'string') {
        return Upload.LIST_IGNORE;
      }
      send('SELECTING_FILE', { value: fileList });
    }
  };

  useEffect(() => {
    if (options?.isSocket) {
      if (socket.current) {
        socket.current.disconnect();
      }

      privateAction((token: string) => {
        socket.current = new SocketIO(
          `chat`,
          {
            nid: member.nid,
            agentName: member.username,
            agentId: member._id,
            teams: member.teamId,
            ticketId: ticket._id,
          },
          token,
        );
      });
    }

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!socket.current) return;
    if (options?.isSocket) {
      socket.current?.on('messageRealtime', onNewMessage);
      socket.current?.getIoManager.on('reconnect', () => {
        send('SOCKET_RECONNECT');
      });
    }
  }, [socket.current]);

  //init
  useEffect(() => {
    if (user._id) {
      if (options?.isTicketManagementMode) {
        // if current ticket is open or assigned, fetch last message
        if (ticket.status !== ETicketStatus.RESOLVED) {
          send('FIRST_FETCH');
          return;
        } else {
          send('FIRST_FETCH_WITH_TIME');
        }
      } else {
        if (!messageTime) {
          send('FIRST_FETCH');
        } else {
          send('FIRST_FETCH_WITH_TIME');
        }
      }
    }
  }, []);

  //keep previous scroll when scroll up and load success
  useEffect(() => {
    if (previousScrollHeight && current.matches({ chatHistoryState: 'fetchPreviousMessageSuccess' })) {
      const lastScrollPosition = listRef.current.scrollHeight - previousScrollHeight;
      scrollTo(lastScrollPosition);
      send('STANDBY');
    }
  }, [current, previousScrollHeight]);

  useEffect(() => {
    //force scroll bottom
    if (scrollBottom) {
      setTimeout(() => {
        scrollTo(listRef.current.scrollHeight);
      }, 0);
    }
  }, [scrollBottom]);

  //call when it has messageTime and user scroll to top it will keep previous scroll
  useEffect(() => {
    if (previousScrollHeight && current.matches({ chatHistoryState: 'fetchPreviousMessagesWithTimeSuccess' })) {
      const lastScrollPosition = listRef.current.scrollHeight - previousScrollHeight;
      scrollTo(lastScrollPosition);
      send('STANDBY_WITH_TIME');
    }
  }, [current, previousScrollHeight]);

  useEffect(() => {
    //do action from states
    doActionFromState();
  }, [current, listRef]);

  const doActionFromState = () => {
    if (current.matches({ chatHistoryState: 'sendMessage' })) {
      scrollTo(listRef.current.scrollHeight);
      send('STANDBY');
      return;
    }
    if (current.matches({ chatHistoryState: 'sendingFilesMessage' })) {
      send('STANDBY');
      return;
    }

    // scroll to bottom when send message failed
    if (current.matches({ chatHistoryState: 'sendMessageFailed' })) {
      const { errorResponse } = current.context;
      if (errorResponse.name === PERMISSION_DENIED_NAME_ERROR) {
        onUpdateDetail(PERMISSION_DENIED, errorResponse.message.replace(/\((.*?)\)/, ''), REFRESH_PAGE_BUTTON);
        onOpen(() => {
          history.goBack();
        });
      }
      scrollTo(listRef.current.scrollHeight);
      send('STANDBY');
      return;
    }
    if (
      current.matches({ chatHistoryState: 'sendMessageWithTimeSuccess' }) ||
      current.matches({ chatHistoryState: 'sendFilesMessageSuccess' })
    ) {
      scrollTo(listRef.current.scrollHeight);
      send('STANDBY');
      return;
    }
    if (current.matches({ chatHistoryState: 'sendMessageSuccess' })) {
      send('STANDBY');
      return;
    }
    //scroll to message at messageTime
    if (current.matches({ chatHistoryState: 'fetchMessagesWithTimeSuccess' })) {
      const findMessageIndex = chatHistory.messages.findIndex(
        (message) => dayjs(message.createdDate).valueOf() === messageTime,
      );

      if (findMessageIndex > -1) {
        const el = document.getElementById(chatHistory.messages[findMessageIndex].getId);
        if (el) {
          scrollTo(el.offsetTop);
        }
        send('STANDBY_WITH_TIME');
      }
    }

    //call when it has messageTime and user scroll down to bottom it will set state to STANDBY_WITH_TIME
    if (current.matches({ chatHistoryState: 'fetchNextMessagesWithTimeSuccess' })) {
      send('STANDBY_WITH_TIME');
    }

    //scroll to bottom when recieve new message
    if (current.matches({ socketState: 'receiveMessage' })) {
      const { chatHistory, currentScrollPosition } = current.context;
      const lastMessage = chatHistory.messages[chatHistory.messages.length - 1];
      // if last message is person message, not owner of the message and user scroll position around bottom
      if (
        lastMessage instanceof isPersonMessage(lastMessage) &&
        lastMessage.getSenderName !== member.username &&
        listRef.current.scrollHeight - currentScrollPosition <= MESSAGE_BOX_BOTTOM_THRESHOLD
      ) {
        scrollTo(listRef.current.scrollHeight);
      }
      send('SOCKET_STANDBY');
    }

    //call when load previous error
    if (current.matches({ chatHistoryState: 'fetchPreviousMessageFailed' })) {
      send('STANDBY');
    }

    //call when upload file success
    if (current.matches({ chatHistoryState: 'uploadFilesSuccess' })) {
      prepareFilesMessage();
    }
  };

  const prepareFilesMessage = () => {
    const { selectedFiles, filesListAfterUpload } = current.context;
    const timestamp = Date.now();
    const newMessages: INewMessage<IImageMessage | IVideoMessage | ILinkMessage>[] = [];
    for (const item of selectedFiles) {
      // ignore photoshop file
      if (item.type.includes(EMessageType.IMAGE) && item.type !== FILE_TYPE.PHOTOSHOP) {
        newMessages.push({
          id: timestamp.toString(),
          eventType: EEventType.MESSAGE,
          messageType: EMessageType.IMAGE,
          sender: ESenderType.AGENT,
          senderName: member.username,
          url: '',
          senderImage: '',
          createdAt: timestamp,
          messageStatus: EMessageStatus.SENDING,
        });
        continue;
      }
      if (item.type.includes(EMessageType.VIDEO)) {
        newMessages.push({
          id: timestamp.toString(),
          eventType: EEventType.MESSAGE,
          messageType: EMessageType.VIDEO,
          sender: ESenderType.AGENT,
          senderName: member.username,
          url: '',
          senderImage: '',
          createdAt: timestamp,
          messageStatus: EMessageStatus.SENDING,
        });
        continue;
      }
      newMessages.push({
        id: timestamp.toString(),
        eventType: EEventType.MESSAGE,
        messageType: EMessageType.LINK,
        sender: ESenderType.AGENT,
        senderName: member.username,
        url: '',
        senderImage: '',
        createdAt: timestamp,
        messageStatus: EMessageStatus.SENDING,
      });
    }
    const messagesState: CombineAllMessage[] = [];
    for (const [index, messageItem] of newMessages.entries()) {
      const url = filesListAfterUpload[index].location;
      messageItem.url = url;
      messagesState.push(
        getMessage({
          id: messageItem.id,
          time: messageItem.createdAt,
          message: messageItem,
        }),
      );
    }

    //create payload before sendMessage
    const messagePayload = {
      payloads: newMessages.map((messageItem) => {
        return {
          eventType: EEventType.MESSAGE,
          messageType: messageItem.messageType,
          sender: ESenderType.AGENT,
          senderName: member.username,
          url: messageItem.url,
        };
      }),
      userId: ticket.userId,
      userSocialId: ticket.userInfo?.referenceId,
      channelId: ticket.channelId,
      ticketId: ticket._id,
      channelType: ticket.channelType,
      createdAt: timestamp,
    };
    if (messageTime && !options?.isTicketManagementMode && current.matches({ chatHistoryState: 'standByWithTime' })) {
      send('SEND_MESSAGE_WITH_TIME', { memberId: member._id, messagePayload, newMessage: messagesState });
      return;
    }
    send('SEND_MESSAGE', { memberId: member._id, messagePayload, newMessage: messagesState });
    setTimeout(() => {
      scrollTo(listRef.current.scrollHeight);
    }, 200);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    // do nothing if first fetching
    if (current.matches('chatHistoryState.firstFetching')) return;
    const scrollOffset = (e.target as HTMLDivElement).scrollTop;
    const previousScrollHeight = listRef.current.scrollHeight - listRef.current.scrollTop;
    send('SET_CURRENT_SCROLL_POSITION', { currentScrollPosition: scrollOffset });
    if (scrollOffset === 0 && chatHistory.previousRemain && current.matches({ chatHistoryState: 'standby' })) {
      send('SCROLL_UP', { previousScrollHeight });
      return;
    }
    if (current.matches({ chatHistoryState: 'standByWithTime' })) {
      if (scrollOffset === 0 && chatHistory.previousRemain) {
        send('SCROLL_UP', { previousScrollHeight });
        return;
      }
      if (listRef.current.offsetHeight + scrollOffset === listRef.current.scrollHeight && chatHistory.nextRemain) {
        send('SCROLL_DOWN');
        return;
      }
    }
  };

  const scrollTo = (position: number) => {
    if (listRef && listRef.current) {
      listRef.current.scrollTop = position;
    }
  };

  const handleResend = async (message: IRealMessage) => {
    if (message instanceof TemplateFailed) {
      // resend template
      const raw = message.instanceData;
      send('RESEND_MESSAGE', {
        memberId: member._id,
        messagePayload: raw.data,
        trackId: raw.id,
        actionType: 'template',
        livechatMessage: raw.livechatMessage,
      });
    } else {
      // update message date send
      message.setCreateDate = Date.now();
      const payload = messagePayloadGenerator({
        member,
        message,
        selectedUser: {
          ...user,
          id: user._id,
          userSocialId: user.referenceId,
        },
        selectedTicket: ticket,
      });
      if (payload) {
        send('RESEND_MESSAGE', { memberId: member._id, messagePayload: payload, actionType: 'message' });
      }
    }
  };

  const onNewMessage = (message: SocketMessage & ISocketEvent) => {
    try {
      socket.current?.setOffset(message.eventId);
      send('FIND_AND_UPDATE_MESSAGE', { message });
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleTextInputChange = (value: string) => {
    send('TYPING', { value });
  };

  const genMessagePayload = (message: string) => {
    if (checkLink(message)) {
      return {
        eventType: EEventType.MESSAGE,
        messageType: EMessageType.LINK,
        sender: ESenderType.AGENT,
        senderName: member.username,
        url: message,
      };
    }

    return {
      eventType: EEventType.MESSAGE,
      messageType: EMessageType.TEXT,
      sender: ESenderType.AGENT,
      senderName: member.username,
      text: message,
    };
  };

  const handleSendMessage = async (message: string) => {
    send('TYPING', { value: '' });
    if (ticket) {
      const timestamp = dayjs().valueOf();
      const msg = genMessagePayload(message);
      const messagePayload = {
        payloads: [msg],
        userId: ticket?.userId,
        userSocialId: ticket.userInfo?.referenceId,
        channelId: ticket.channelId,
        ticketId: ticket._id,
        channelType: ticket?.channelType,
        createdAt: timestamp,
      };
      const newMessage = getMessage({
        id: `${messagePayload.createdAt}`,
        message: {
          id: `${messagePayload.createdAt}`,
          ...msg,
          senderImage: '',
          createdAt: messagePayload.createdAt,
          messageStatus: EMessageStatus.SENDING,
        } as INewMessage<ITextMessage | ILinkMessage | IVideoMessage>,
        time: messagePayload.createdAt,
      });

      send('SEND_MESSAGE', { memberId: member._id, messagePayload, newMessage, actionType: 'message' });
    }
  };

  const handleSendTemplate = (template: BOTS.ITemplateApi) => {
    if (ticket && user) {
      const messagePayload = genTemplatePayload(
        template,
        user.id,
        user.referenceId ?? '',
        ticket.channelId,
        ticket._id,
        ticket.channelType,
      );
      const { newMessage, trackId } = getMessageFromTemplate(template, member.username ?? '');
      send('SEND_MESSAGE', {
        newMessage,
        messagePayload,
        actionType: 'template',
        trackId: trackId,
        template,
        previewMessage: template.messages,
      });
    }
  };

  // files handler
  const beforeUpload = (_file: RcFile, fileList: RcFile[]) => {
    if (!ticket) return Upload.LIST_IGNORE;
    const validateResult = validateFiles(ticket, fileList, uploadRef, onUpdateDetail, onOpen);
    if (typeof validateResult === 'string') {
      return Upload.LIST_IGNORE;
    }
    send('SELECTING_FILE', { value: fileList });
    return false;
  };

  const handleCancelUpload = () => {
    send('CLEAR_SELECTING_FILE', { value: [] });
  };

  const handleUploadFile = () => {
    send('UPLOAD_FILE', { member });
  };

  const isNormalStandbyAndDisplayBottomBtn = () => {
    const listHeight = listRef.current?.scrollHeight - listRef.current?.offsetHeight;
    return (
      current.hasTag('normal-fetch-task') &&
      listHeight - current.context.currentScrollPosition > MESSAGE_BOX_BOTTOM_THRESHOLD
    );
  };

  const isStandbyWithTimeAndDisplayBottomBtn = () => {
    return (
      (chatHistory.nextRemain && current.matches({ chatHistoryState: 'standByWithTime' })) ||
      current.matches({ chatHistoryState: 'fetchingNextMessagesWithTime' }) ||
      current.matches({ chatHistoryState: 'fetchNextMessagesWithTimeSuccess' })
    );
  };

  return {
    current,
    listRef,
    uploadRef,
    messageContext: current.context,
    _renderDownButton: () => {
      if (listRef.current) {
        if (isNormalStandbyAndDisplayBottomBtn()) {
          return <DownButton onClick={() => scrollTo(listRef.current.scrollHeight)} />;
        }
        if (isStandbyWithTimeAndDisplayBottomBtn()) {
          return <DownButton onClick={() => send('FIRST_FETCH')} />;
        }
      }
      return null;
    },
    disabledSendButton: current.context.textMessage.length === 0 || current.context.textMessage.trim() === '',
    beforeUpload,
    handleSendMessage,
    send,
    scrollTo,
    handleScroll,
    handleResend,
    handleTextInputChange,
    handleCancelUpload,
    handleUploadFile,
    handleSendTemplate,
  };
};

export default useMessageBox;
